// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-detailed-pages-action-js": () => import("./../../../src/detailed-pages/Action.js" /* webpackChunkName: "component---src-detailed-pages-action-js" */),
  "component---src-detailed-pages-article-js": () => import("./../../../src/detailed-pages/Article.js" /* webpackChunkName: "component---src-detailed-pages-article-js" */),
  "component---src-detailed-pages-news-js": () => import("./../../../src/detailed-pages/News.js" /* webpackChunkName: "component---src-detailed-pages-news-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actions-js": () => import("./../../../src/pages/actions.js" /* webpackChunkName: "component---src-pages-actions-js" */),
  "component---src-pages-actualites-js": () => import("./../../../src/pages/actualites.js" /* webpackChunkName: "component---src-pages-actualites-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-ok-js": () => import("./../../../src/pages/contact-ok.js" /* webpackChunkName: "component---src-pages-contact-ok-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

